import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { EssayHelper } from "../../helpers/essay.helper";
import AppBar from '@mui/material/AppBar';
import ScanPopup from './scan-popup';
import ScanCam from './scan-cam';
import {
  Avatar, Button, Box, Card, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel,
  Menu, MenuItem,
  Select, TextField, Toolbar, Typography, FormControlLabel, Checkbox
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { auth } from '../../firebase';
import { useUser } from '../../UserContext';
import { AppHelper } from '../../AppHelper';
const configJson = require('../../config.json');
const dayjs = require('dayjs');

export default function EssayForm(props) {
  const { drawerWidth, isClosing, viewMode, essayFormDone, profileId } = props;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isScanCamOpen, setIsScanCamOpen] = useState(false);
  const navigate = useNavigate();
  const [authOK, setAuthOK] = React.useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ oUser, setOUser] = React.useState(null);
  const [isCropped, setIsCropped] = useState(false);
  const [msgText, setMsgText] = React.useState('');
  const [msgText2, setMsgText2] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const txtName = useRef(null);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [essayTitle, setEssayTitle] = React.useState('');
  const [vMode, setVMode] = React.useState('NEW');
  const [charCount, setCharCount] = React.useState(0);
  // const [gradeOK, setGradeOK] = useState(true);
  const [grade, setGrade] = useState('P5');
  const [version, setVersion] = useState('3.0');
  const [versionCorr, setVersionCorr] = useState('2.0');
  
  const [imgs, setImgs] = useState([]);
  
  const [doneEval, setDoneEval] = React.useState(false);
  const [doneCorrect, setDoneCorrect] = React.useState(false);
  const [evalStatus, setEvalStatus] = React.useState('Grammar');
  const [evalStatusP, setEvalStatusP] = React.useState(0);
  const [isSa, setIsSa] = React.useState(false);
  const gradeList0 = [
    // { v: 'P1', n: 'PRIMARY 1'},
    // { v: 'P2', n: 'PRIMARY 2'},
    // { v: 'P3', n: 'PRIMARY 3'},
    { v: 'P4', n: 'PRIMARY 4', wMin: 120},
    { v: 'P5', n: 'PRIMARY 5', wMin: 150},
    { v: 'P6', n: 'PRIMARY 6', wMin: 150}
  ];
  const verList = [
    { v: '1', n: 'Score 1.0'},
    { v: '2', n: 'Score 2.0'},
    { v: '2.1', n: 'Score 2.1'},
    { v: '2.2', n: 'Score 2.2'},
    { v: '3.0', n: 'Score 3.0'},
  ];
  const verListCorr = [
    { v: '1.0', n: 'Correction 1.0'},
    { v: '2.0', n: 'Correction 2.0'},
  ];
  const [formValid, setFormValid] = React.useState(true);
  const [formMsgError, setFormMsgError] = React.useState('');
  const [bodyRows, setBodyRows] = React.useState(10);
  const refPopScan = useRef(null);
  
  const fetchGrades = async () => {
	 var grade = await EssayHelper.essayGradeGet(oUser.token);
	 setGradeList(grade);
  };
 
   useEffect(() => {
	  if (isPopupOpen) {
		txtName.current.value = ""; // Reset the value of txtName input field
	  }
	}, [isPopupOpen]);

  useEffect(()=>{
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);
    setOUser(_user);
    if(_user && _user.grade){
	  fetchGrades();
      setGrade(_user.grade);
    }
	
  }, []);
  useEffect(() => {
    if(props.viewMode)
      setVMode(props.viewMode);
  }, [props.viewMode]);
  useEffect(() => {
    console.log(props.profileId);
  }, [props.profileId]);
  useEffect(() => {
    setIsSa(props.isSa);
  }, [props.isSa]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
   const handleCropChange = (event) => {
    setIsCropped(event.target.checked); // Update state when checkbox is clicked
  };

  const handleClose = () => { setAnchorEl(null) };
  const msgTextChange = (arg) =>{
    //setMsgBox(arg.target.value);
    var _txt = arg.target.value;
    setMsgText(_txt);
    const words = _txt.trim().split(/\s+/);
    setCharCount(words.length);

    const textareaLineHeight = 35;
    const previousRows = arg.target.rows;
    const currentRows = Math.ceil(arg.target.scrollHeight / textareaLineHeight);
    arg.target.rows = currentRows;
    if (currentRows !== previousRows) {
      setBodyRows(currentRows);
    }
  };
  
  const divRef = useRef(null);
  const msgTextChangeV2 = () => {
    const _txt = divRef.current.innerText.trim();
    // setMsgText(_txt);
    setMsgText2(_txt);
    const words = _txt.trim().split(/\s+/);
	
    setCharCount(words.length);
  };
  
  const msgTextPaste = (event) => {
    // const _txt = event.clipboardData.getData('text');
    // setMsgText(_txt);
    // const words = _txt.trim().split(/\s+/);
    // setCharCount(words.length);
  };
  const checkEssayStatus = async(argEssayId) =>{
    var _res = await AppHelper.apiPost('api/essay/detail', {token: oUser.token, essayId: argEssayId});
    if(!_res || !_res.status){
      alert('ERROR: Failed to load!');
      return;
    }
    console.log('essay-detail', _res.data);
    checkEssayEvalStatus(_res.data.remark);
    if(_res.data.status == 8){  //-- ERROR in EVAL or CORR
      console.log('error: ');
      alert('Submission failed. Please try again!');
      setVMode('NEW');
      // setTimeout(() => {
      //   essayFormDone({code:'ERROR', essayId: argEssayId});
      // }, 1.5*1000);
      return;
    }
    if(_res.data.status != 5){
      if(_res.data.status == 3)
        setDoneEval(true);
      if(_res.data.status == 4)
        setDoneCorrect(true);

      setTimeout(() => {
        checkEssayStatus(argEssayId);
      }, 3*1000);
      return;
    }
    setTimeout(() => {
      essayFormDone({code:'NEW', essayId: argEssayId});
    }, 1.5*1000);
  }
  const checkEssayEvalStatus = (argStatus) =>{
    var _rtn = '';
    switch(argStatus){
      case '1111':
        _rtn = 'Finishing';
        setEvalStatusP(100);
        break;
      case '111':
        _rtn = 'Flow & Structure';
        setEvalStatusP(75);
        break;
      case '11':
        _rtn = 'Vocabulary';
        setEvalStatusP(50);
        break;
      case '1':
        _rtn = 'Grammar';
        setEvalStatusP(25);
        break;
      default:
        _rtn = 'Spelling';
        setEvalStatusP(0);
        break;
    }
    setEvalStatus(_rtn);
    // var x = 1;
    // var y = 3;

    // // Convert x and y to binary strings and pad them with zeroes to ensure consistent length
    // var binaryX = x.toString(2).padStart(8, '0'); // Assuming 32-bit representation for simplicity
    // var binaryY = y.toString(2).padStart(8, '0');

    // // Check if the first bit of binaryX and binaryY are the same
    // var firstBitSame = (parseInt(binaryX[0]) & parseInt(binaryY[0])) > 0;

    // console.log(firstBitSame, binaryX, binaryY); // Output: true or false
  }
  
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  
  const onScan = async() => {
	  setPopupOpen(true);
  };
  
  const onSubmit = async() =>{
    console.log(version, versionCorr, profileId);
    var _topic = txtName.current.value;
    if(!_topic || _topic.length<1){
      setFormMsgError(`Please enter the Essay Title.`);
      setFormValid(false);
      return;
    }
    var _grade = gradeList.find(g=>g.v == grade);
    if(!_grade){
      setFormMsgError('Please select Grade.');
      setFormValid(false);
      return;
    }
	
    if(charCount < _grade.wMin){
      setFormMsgError(`Please submit an essay with a minimum of ${_grade.wMin} words to evaluate at a ${_grade.n} level.`);
      setFormValid(false);
      return;
    }
    if(charCount>700){
      setFormMsgError('Submission limited to 700 words. Please review and adjust as necessary.');
	  setFormValid(false);
      return;
    }
    //if(charCount)
    //setVMode('PROCESSING');
    setDoneEval(false);
    setDoneCorrect(false);
    var _msgText = msgText2;
    console.log('_msgText', _msgText);
	_msgText = _msgText.replace(/<div style='color:white;background-color:blue;display:inline-block'>Page Break<\/div>/g, "");
	//_msgText = _msgText.replace(/<span style="color:#7486ec;background-color: yellow">(.*?)<\/span>/g, '$1');
	_msgText = _msgText.replace(/<[^>]*>/g, "");
    _msgText = _msgText.replace(/“/g,'"');
    _msgText = _msgText.replace(/”/g,'"');
    _msgText = _msgText.replace(/‘/g,"'");
    _msgText = _msgText.replace(/’/g,"'");
    _msgText = _msgText.replace(/\n\n\n/gi,"\n\n");
    _msgText = _msgText.replace(/,(?!\s)/g, ', '); //-- add space behind comma if there is not.
    _msgText = _msgText.replace(/\s{2,}/g, ' '); //-- remove space when multiple continuous spaces. (Unnecessary Space)
    _msgText = _msgText.replace(/\s*,/g, ','); //-- replace the space before comma if any. (Unnecessary Space)
    var _url = 'api/essay/create';
    if(version == '2') _url += '/v2';
    else if(version == '2.1') _url += '/v2.1/';
    else if(version == '2.2') _url += '/v2.2/';
    _url = 'api/essay/create/withVersion';

    var _payload = {token: oUser.token, imgs:imgs, topic: _topic, grade: grade, addScore:0, text:_msgText, profileId:profileId};
    _payload.verScore = 'v' + version;
    _payload.verCorr = 'v' + versionCorr;
    console.log('api call', _url, _payload);
    // if(1==1){
    //   setFormMsgError('TEST');
    //   return;
    // }
    setVMode('PROCESSING');
    checkEssayEvalStatus('');
    var _res = await AppHelper.apiPost(_url, _payload);
    var _resEssay = _res.data;
    if(!_res || !_res.status){
      alert('ERROR: Failed to create!');
      setVMode('NEW');
      setEssayTitle(_topic);
      return;
    }
    else {
      //alert('Submitted!');
      if(essayFormDone){
        setVMode('PROCESSING');
        // var _resAi = await AppHelper.apiAiPost('essay/process/eval', {essay: msgText});
        // var _resAiCorr = await AppHelper.apiAiPost('essay/process/correct', {essay: msgText});
        // setVMode('VIEW');
        // var _scoreList = [];
        // var _scoreRes = 'NA';
        // if(_resAi.data && _resAi.data.resEval){
        //   _scoreList = JSON.parse(_resAi.data.resEval);
        //   _scoreRes = _resAi.data.resEval;
        // }
        // var _corrRes = 'NA';
        // // if(_resAi.data && _resAi.data.resCorr){
        // //   _corrRes = _resAi.data.resCorr;
        // // }
        // if(_resAiCorr.data && _resAiCorr.data.resCorr){
        //   _corrRes = _resAiCorr.data.resCorr;
        // }
        // var _res3 = await AppHelper.apiPost('api/essay/process', 
        //   {
        //     token: oUser.token, essayId: _resEssay.essayId, status: 3,
        //     scoreList: _scoreList,
        //     scoreResult: _scoreRes,
        //     correctionResult: _corrRes
        //   });
        checkEssayStatus(_resEssay.essayId);
        //essayFormDone(_res);
      }
    }
  };
  
  const handleScanComplete = (result, imgs) => {
	  var fullBody = "";
	  
      result.data.forEach(item => {
		const { title, body } = item;
		
		// remove html tag
		var formattedBody = body.replace(/<[^>]*>/g, '');
		console.log('Scan completed with title: ' + title + ', body: ' + body);
		
		// get title
		if(title != "" && title != "Untitled" && txtName.current.value == "")
		{
			txtName.current.value = title;
		}
		
		// replace []
		formattedBody = formattedBody.replace(/\[(.*?)\]/g, '<span style="color:#7486ec;background-color: yellow">$1</span>');
		
		// replace \n with <br/>
        formattedBody = formattedBody.replace(/\n/g, '<br/>');		
		fullBody+=formattedBody;
		
		fullBody+="<div style='color:white;background-color:blue;display:inline-block'>Page Break</div>";
	  });
	  
	  fullBody = fullBody.replace(/^<br\/>/, '');
      fullBody = fullBody.replace(/<div style='color:white;background-color:blue;display:inline-block'>Page Break<\/div>$/, '');

	  const words = fullBody.trim().split(/\s+/);
	  setCharCount(words.length);
	  setImgs(imgs);
	  setMsgText2(fullBody);
	  setMsgText(fullBody);	  
  };
  
  const gradeSelect = async(arg) =>{
    setGrade(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const versionSelect = async(arg) =>{
    setVersion(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const versionSelectCorr = async(arg) =>{
    setVersionCorr(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const calculateRows = (text) => {
    const lineCount = text.split('\n').length;
    return Math.max(lineCount, 13);
  };

  const onCamOpen = ()=>{
    console.log('onCamOpen');
    setIsScanCamOpen(true);
  }
  const onCamClose = ()=>{
    console.log('onCamClose');
    setIsScanCamOpen(false);
  }
  const onCamCapture = (arg)=>{
    setIsScanCamOpen(false);
    refPopScan.current.handleCamCapture(arg);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      { vMode != 'PROCESSING' &&
      <>
      <Grid container mt={0}>
        <Grid item xs={12}>
        {/* <Box display="flex" justifyContent="center">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                Box 1
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign={{ xs: 'left', sm: 'right' }}>
                Box 2
              </Box>
            </Grid>
          </Grid>
        </Box> */}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="1">
          { isSa && (
            <>
            <Select labelId='lblVer' 
              value={version}
              onChange={versionSelect}
              inputProps={{ //disableUnderline: true
              }}
              sx={{ "& fieldset": { border: 'none' } }}
            >
              {verList.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
            <Select labelId='lblVerCorr' 
              value={versionCorr}
              onChange={versionSelectCorr}
              inputProps={{ //disableUnderline: true
              }}
              sx={{ "& fieldset": { border: 'none' } }}
            >
              {verListCorr.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
            </>
          )}
          </Box>
          <Box>
            {/* <Select labelId='lblProfile' 
              value={profileId}
              onChange={profileSelect}
              label=""
              sx={{ "& fieldset": { border: 'none' } }}
            >
              {profiles.map((item) => (
                <MenuItem key={item.profileId} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select> */}
            <Select labelId='lblGrade' 
              value={grade}
              onChange={gradeSelect}
              label=""
              inputProps={{
                //disableUnderline: true
              }}
              sx={{ "& fieldset": { border: 'none' } }}
            >
              {gradeList.map((item) => (
                <MenuItem key={item.v} value={item.v}>
                  {item.n}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="1" textAlign="center" color={'red'} fontWeight={'bold'}>
            { !formValid &&
              <div>
                {formMsgError}
              </div>
            }
          </Box>
        </Box>
          <Box backgroundColor={'yellow'} display={'flex'} justifyContent={'right'} >
            <Box  pt={0} backgroundColor={'red'}>
              {/* <FormControl variant="standard" error={!gradeOK} inputProps={{ disableUnderline: true }} sx={{ "& fieldset": { border: 'none' } }}> */}
                {/* <InputLabel id='lblGrade'>GRADE</InputLabel> */}
              {/* </FormControl> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} 
          sx={{
            backgroundColor:'#fff', borderRadius:'20px', border:'1px solid rgba(0, 0, 0, 0.12)',
            //overflowY:'hidden', 
            '&::-webkit-scrollbar': { width: '6px', },
            '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
            '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px', },
            '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
          }}>
          <Card variant="outlined" 
            sx={{
              padding:1.5, marginRight:0, border:'none', overflowY:'auto', borderRadius:'20px',
              '&::-webkit-scrollbar': { width: '6px', },
              '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
              '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px', },
              '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
            }}>
            <Grid item xs={12} mb={1} mt={1}>
              <Box sx={{fontWeight:'bold', textAlign:'center', paddingLeft:'10px', paddingRight:'10px'}}>
                <TextField 
                  inputRef={txtName}
                  //value={essayTitle}
                  error={!txtNameOK} label="" variant="standard" fullWidth 
                  // fontFamily: "Playpen Sans", fontSize:'2rem', textAlign:'right', 
                  sx={{ "& fieldset": { border: 'none' }}} 
                  inputProps={{
                    //disableUnderline:true, 
                    style: {
                      fontFamily: "Playpen Sans", fontSize:'2rem',
                      textAlign:'center', //disableUnderline: true
                    }
                  }}
                  placeholder="TITLE" 
                  spellCheck="false" autoComplete="off" autoCorrect="off" autoCapitalize="off"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box 
                sx={{
                  fontSize:'1.3rem', lineHeight:'35px', fontFamily: "'Playpen Sans', cursive", height:'30rem', 
                  border:'0 solid #efefef', borderRadius:'6px', padding:'5px', marginLeft:'5px', 
                }}>
				
				 <div
				  ref={divRef}
				  spellCheck="false"
				  onInput={msgTextChangeV2}
          onPaste={msgTextPaste}
          // onKeyDown={(event) => {
          //   if (event.key === 'Enter') {
          //     event.preventDefault(); 
          //     const selection = window.getSelection();
          //     const range = selection.getRangeAt(0);
          //     const br = document.createElement('br');
          //     range.deleteContents();
          //     range.insertNode(br);
          //     range.setStartAfter(br);
          //     range.collapse(true);
          //     selection.removeAllRanges();
          //     selection.addRange(range);
          //   }
          // }}
          contentEditable="true"
	        dangerouslySetInnerHTML={{ __html: msgText }}
          style={{
            fontSize:'1.3rem', fontFamily: "'Playpen Sans', cursive", lineHeight:'35px',
            background:'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize:'100% 35px',
					  minHeight:'25rem',
					  direction: 'ltr', // Ensure left-to-right text direction
            width:'100%', textAlign:'left', padding:'0', border: 'none', outline: 'none', overflow: 'auto', 
          }}
        >
    </div>
				
                <textarea 
                  onChange={msgTextChange}
                  onPaste={msgTextPaste}
                  value={msgText} 
                  //rows={15} 
                  //rows={calculateRows(msgText)}
                  rows = {bodyRows}
                  style={{ 
				    display: 'none', // Hide the textarea
                    fontSize:'1.3rem', fontFamily: "'Playpen Sans', cursive", lineHeight:'35px',
                    background:'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize:'100% 35px',
                    width:'100%', textAlign:'left', padding:'0', border: 'none', outline: 'none', overflow: 'auto', 
                    //background:'none'
                  }} 
                  spellCheck="false" autoComplete="off" autoCorrect="off" autoCapitalize="off"
                >
                </textarea>
              </Box>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{mb:1}}>
            {charCount}/700 words
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end" alignItems='center' flexDirection='column'>
            {/* {validPhone && <Fab onClick={onWhatsapp} sx={{width:'4rem', height:'4rem', marginTop:'3px'}} color="" aria-label="Login">
              <img src="/img/WhatsApp_icon.png.webp" alt="Send Whatsapp" style={{width:'75%'}} />
            </Fab>} */}
          </Box>
        </Grid>
      </Grid>
	  <Grid item>
      {/* { isSa && (
      <>      
        <FormControlLabel
          control={
            <Checkbox color="primary" 
              checked={isCropped} 
              onChange={handleCropChange} 
            />}
          label="AUTO DETECT"
        />
      </>
      )} */}
	  </Grid>
      <Grid container pt={0.5} justifyContent="space-between">
		  <Grid item>
			<Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={onScan} color="info" variant="contained">{'SCAN'}</Button>
		  </Grid>
		  <Grid item>
			<Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={onSubmit} color="info" variant="contained">{isEdit? 'UPDATE':'SUBMIT'}</Button>
		  </Grid>
	</Grid>
	<ScanPopup isClear={false}  isOpen={isPopupOpen} isCropped={isCropped} onClose={handleClosePopup} onScanComplete={handleScanComplete} onCamOpen={onCamOpen} ref={refPopScan} />
  <ScanCam isClear={false}  isOpen={isScanCamOpen} onClose={onCamClose} onScanComplete={handleScanComplete} onCamCapture={onCamCapture} /> 
      </>
      }
      { (vMode == 'PROCESSING' || 1==2) &&
        <Grid container mt={1}>
          <Grid item xs={12} mt={1}>
            Processing..
            <LinearProgress color="info" sx={{marginTop:'1rem'}} />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Box display={'flex'} alignItems={'center'}>
            { !doneEval &&
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress />
                <Box
                  sx={{
                    top: 0, left: 0, bottom: 0, right: 0,
                    position: 'absolute',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.info">
                    {`${evalStatusP}%`}
                  </Typography>
                </Box>
              </Box>
            }
              { doneEval &&
                (
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={100} color="success" />
                    <Box
                      sx={{
                        top: 0, left: 0, bottom: 0, right: 0,
                        position: 'absolute',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.info">
                        {`100%`}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              <Typography sx={{marginLeft:'10px'}}>Evaluation ({evalStatus})</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box display={'flex'} alignItems={'center'}>
              { !doneCorrect &&
                <CircularProgress />
              }
              { doneCorrect &&
                (
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={100} color="success" />
                    <Box
                      sx={{
                        top: 0, left: 0, bottom: 0, right: 0,
                        position: 'absolute',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.info">
                        {`100%`}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              <Typography sx={{marginLeft:'10px'}}>Correction</Typography>
            </Box>
          </Grid>
        </Grid>
      }
    </Box>
  );
}