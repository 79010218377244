import './essay-view.css';
import '../../App.css';
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
	Avatar, Button, Box, Card, Chip, chipClasses, CircularProgress, Dialog, DialogTitle, Fab, FormControl, Grid, IconButton, InputLabel,
	ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, DialogContent,
	TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Tooltip, Typography
} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { diffChars, diffWords, diffWordsWithSpace } from 'diff';
import { AppHelper } from '../../AppHelper';
import ScanView from './scan-view';
import EssayStatus from './essay-status';
import EssayViewQuestion from './essay-view-question';
import PopErrorAdd from './pop-error-add';
import PopGMErrors from './pop-GM-errors';
import PopSPErrors from './pop-SP-errors';
import PopVCAErrors from './pop-VCA-errors';
import PopVCXErrors from './pop-VCX-errors';
import PopScore from './pop-score';
import PopComment from "../common/pop-comment";
import PopInProgress from '../common/pop-inprogress';
import PopResubmit from '../common/pop-resubmit';
import ScoreSubExpand from './score-sub-expand';
import dayjs from 'dayjs';
import {QRCodeSVG} from 'qrcode.react';
import { useEssayContext } from "../../contexts/context-essay";
import { EssayHelper } from '../../helpers/essay.helper';
import PopShare from './pop-share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const configJson = require('../../config.json');

export default function EssayView(props) {
	const { drawerWidth, isClosing, viewMode, essayFormDone, profileId, isSa, isSample, isShared, printMode, scroll2End, qrValue, userToken } = props;
	
	const [comments, setComments] = useState({
    GM: {list: []},
    SP: {list: []},
    VC: {list: []},
    ST: {list: []},
    OA: {list: []},
  });
	const [essayLog, setEssayLog] = React.useState({});
	const [grade, setGrade] = useState('P5');
	const gradeList = [
    { v: 'P1', n: 'Primary 1'},
    { v: 'P2', n: 'Primary 2'},
    { v: 'P3', n: 'Primary 3'},
    { v: 'P4', n: 'Primary 4'},
    { v: 'P5', n: 'Primary 5'},
    { v: 'P6', n: 'Primary 6'}
  ];
	const [iamTEA, setIamTEA] = useState(false);
  const [iamTUI, setIamTUI] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isMyEssay, setIsMyEssay] = useState(false);
	const [isOK_GM, setIsOK_GM] = useState(false);
  const [isOK_SP, setIsOK_SP] = useState(false);
  const [isOK_VC, setIsOK_VC] = useState(false);
  const [isOK_ST, setIsOK_ST] = useState(false);
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [isResubmitting, setIsResubmitting] = useState(false);
	const [oUser, setOUser] = useState(null);
	const [pdfDownloading, setPdfDownloading] = useState(false);
	const [popShareOpen, setPopShareOpen] = React.useState(false);
  const [popCmtText, setPopCmtText] = useState('');
	const [popCmtOpen, setPopCmtOpen] = useState(false);
	const [popCmtType, setPopCmtType] = useState('');
	const [recEssay, setRecEssay] = useState({essayId:'', imgs:[], profileId: '', topic:'', grade:'', text: '', email:'', student:''});
	const [recEssayC, setRecEssayC] = useState('');
	const [recGrade, setRecGrade] = React.useState('');
	const [recVersion, setRecVersion] = useState(1);
	const [startCheckStatus, setStartCheckStatus] = useState(false);
  const [popSpellingErrorsOpen, setPopSpellingErrorsOpen] = React.useState(false);
  const [scoreInfoSP, setScoreInfoSP] = React.useState({});
  const [popGMErrorsOpen, setPopGMErrorsOpen] = React.useState(false);
  const [scoreInfoGM, setScoreInfoGM] = React.useState({});
  const [scoreInfoVC, setScoreInfoVC] = React.useState({});
  const [scoreInfoVCX, setScoreInfoVCX] = React.useState({});
  const [popVCErrorsOpen, setPopVCErrorsOpen] = React.useState(false);
  const [popVCXOpen, setPopVCXOpen] = React.useState(false);
	const [wordsCount, setWordsCount] = useState(0);

  const boxOverall = useRef(null);

	useEffect(() => {
    AppHelper.apiAuditPost("P-ES", props.essayId || "");
  }, []);
  useEffect(()=>{
    setIsMyEssay(oUser && oUser.profileId && recEssay && oUser.profileId == recEssay.profileId);
    setTimeout(() => {
      if(scroll2End){
        const element = document.getElementById('boxWordCount');
        if (element) {
          const rect = element.getBoundingClientRect();
          const y = rect.top + window.scrollY;
          window.scrollTo({ top: y-45, behavior: 'smooth' });
        }
      }
    }, 1000*1);
  }, [oUser, recEssay]);
	useEffect(() => {
    setStartCheckStatus(false);
    fetchUserDetail();
    const controller = new AbortController();
    if(props.essayId){
      const fetchSignal = controller.signal;
      fetchEssayDetail(props.essayId, fetchSignal);
    }

    return () => {
      controller.abort();
    };
  }, [props.essayId]);
	
  const browseExercise = ()=>{
    window.open('/exercise', '_blank');
  }
  const convertNewlinesToBr = (text) => {
    if(!text) return <React.Fragment></React.Fragment>;
	  text = text.replace(/<[^>]*>/g, '');
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };
  const copyStudentEssay = ()=>{
    console.log(recEssay.text);
    navigator.clipboard.writeText(recEssay.text);
    alert('Copied to clipboard!');
  }
	const fetchEssayDetail = async(argId, fetchSignal)=>{
		var _user = AppHelper.userCacheGet();
    if(!_user){
      _user = {token:'NA'};
    }
    const _isTEA = _user.accountType == 'TEA';
    setIsLoading(true);
    setRecGrade('');
    setRecVersion(1);
    setRecEssayC('');
    var _token = _user.token;
    if(userToken && (!_token || _token=='NA')) _token = userToken;
		var _rec = await EssayHelper.essayDetailScore(_token, argId, iamTEA);
		console.log('rec', _rec);
		setIsLoading(false);
		if(!_rec){
      //alert('Failed to load the essay scores');
      return;
    }
    _rec.scores.forEach(_scTmp=>{
      if(!_scTmp.eg) _scTmp.eg = [];
      if(!_scTmp.sub) _scTmp.sub = [];
    });
		setGrade(_rec.grade);
    var _grade = gradeList.find(g=>g.v == _rec.grade);
    if(_grade)
      setRecGrade(_grade.n);

		if(_rec.correctionResult && _rec.correctionResult != 'NA'){
      try{
        var _jsonStr = _rec.correctionResult.replace(/({\r\n|{\n|{\r)/gm, "{");
        _jsonStr = _jsonStr.replace(/(\r\n}|\n}|\r})/gm, "}");
        _jsonStr = _jsonStr.replace(/(\r\n|\n|\r)/gm, "\\n");

        var _jsonCorr = JSON.parse(_jsonStr);
        setRecEssayC(_jsonCorr.correctedEssay);
      }
      catch(eCorr){
        console.log(eCorr);
        setRecEssayC(_rec.correctionResult);
      }
    }
		var _needResubmit = false;
    if(_rec.scoreResultGM && _rec.scoreResultGM.length>0) setIsOK_GM(true);
    else{
      setIsOK_GM(false);
      _needResubmit = true;
    }
    if(_rec.scoreResultSP && _rec.scoreResultSP.length>0) setIsOK_SP(true);
    else{
      setIsOK_SP(false);
      _needResubmit = true;
    }
    if(_rec.scoreResultVC && _rec.scoreResultVC.length>0) setIsOK_VC(true);
    else{
      setIsOK_VC(false);
      _needResubmit = true;
    }
    if(_rec.scoreResultST && _rec.scoreResultST.length>0) setIsOK_ST(true);
    else{
      setIsOK_ST(false);
      _needResubmit = true;
    }
    if(_needResubmit){
      console.log('resubmit', _rec, _needResubmit);
      //setPopResubmitOpen(true);
    }
    else console.log('no-resubmit', _rec, _needResubmit);

		setRecVersion(_rec.version);
		setWordsCount(wordCount(_rec.text));
		if((scroll2End) && _rec.scores){
      _rec.scores.forEach(rs=>{
        rs.expand = true;
      });
    }
    setRecEssay(_rec);
		console.log('rec-essay', _rec);

    // var _res = await AppHelper.apiPost('api/essay/detail', {token: _user.token, essayId: argId}, fetchSignal);
    // var _resLog = await AppHelper.apiPost('api/log/essay', {token: _user.token, essayId: argId}, fetchSignal);
    // if(!_res || !_res.status || !_res.data){
    //   setIsLoading(false);
    //   console.log('ERROR: Failed to load the Essay Details!');
    //   return;
    // }
		// console.log('essay detail >>>', _res);
    // setRecEssay({});
		// if(_res.data.status < 5 && _res.data.status > 0){ // inprogress
    //   if(_res.score && _res.score.length>0 && (_res.score[0].version == '1.00')){
    //     //-- skip for score v1.00 - old essays
    //   }
    //   else{ 
    //     var _diffHours = dayjs.utc().diff(dayjs(_res.data.timestampUtc), 'hour');
    //     if(_diffHours>1){
    //       //console.log('resubmit', _res.data.status, _diffHours);
    //       //setPopResubmitOpen(true);
    //     }
    //     else{
    //       //setPopInprogressOpen(true);
    //       console.log('inprogress', dayjs.utc().format('YYMMDD-HHmmss'), dayjs(_res.data.timestampUtc).format('YYMMDD-HHmmss'), _diffHours, _res.data);
    //       setRecEssay({essayId:_res.data.essayId});
    //       return;
    //     }

    //   }
    // }
    // if(_resLog) setEssayLog(_resLog.message);
	}
  const fetchEssayDetail_sync = async(argId)=>{
    var _user = AppHelper.userCacheGet();
    if(!_user){
      _user = {token:'NA'};
    }
    const _isTEA = _user.accountType == 'TEA';
    var _token = _user.token;
    if(userToken && (!_token || _token=='NA')) _token = userToken;
		var _rec = await EssayHelper.essayDetailScore(_token, argId, iamTEA);
		console.log(_rec);
		if(!_rec){
      //alert('Failed to load the essay scores');
      return;
    }
    //var _rec = {...recEssay}
    setRecEssay(_rec);
  }
	const fetchUserDetail = ()=>{
    var _user = AppHelper.userCacheGet();
    if(!_user){
      _user = {token:'NA'};
    }
    //setAuthOK(_user != null);
    setOUser(_user);
    setIamTEA(_user.accountType == 'TEA');
    setIamTUI(_user.accountType == 'TUI');
    console.log(_user.accountType, 'acc type');
  }
  const getDifferences = () => {
    if(!recEssay.text || !recEssayC) return <span></span>;
    const differences = diffWordsWithSpace(recEssay.text, recEssayC);
    return differences.map((part, index) => {
      const style = {
        color: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#000',
        // color: part.added ? '#304ffe' : part.removed ? '#d50000' : '#000',
        // backgroundColor: part.added ? '#e1f5fe' : part.removed ? '#fff3e0' : '#fff',
        // color: part.added ? '#fff' : part.removed ? '#fff' : '#000',
        // backgroundColor: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#fff',
        textDecoration: part.added? 'underline' : part.removed ? 'line-through' : 'none',
        fontWeight: (part.added || part.removed) ? 'bold' : 'normal',
      };
      return <span key={index} style={ style }>{convertNewlinesToBr(part.value)}</span>;
    });
  };
	const handleClosePopup = () => {
    setPopupOpen(false);
  };
  
  const onShare = async() => {
	  setPopShareOpen(true);  
  }
  
  const onDelete = async() =>{
    if(!window.confirm('Are you sure you want to delete this Essay?')) return;
    var _res = await AppHelper.apiPost('api/essay/remove', {token: oUser?oUser.token:'NA', essayId: recEssay.essayId});
    if(!_res || !_res.status){
      alert('ERROR: Failed to delete!');
      return;
    }
    else {
      //alert('Deleted!');
      if(essayFormDone){
        _res.code = 'DELETE';
        _res.essayId = recEssay.essayId;
        essayFormDone(_res);
      }
    }
  };
  const onMarkDoneGM = async(argErrorCount)=>{
    var _url = 'api/essay/score/update/GM'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:argErrorCount};
	  var _res = await AppHelper.apiPost(_url, _payload);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onMarkDoneVCA = async(argErrorCount)=>{
    var _url = 'api/essay/score/update/VCA'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:argErrorCount};
	  var _res = await AppHelper.apiPost(_url, _payload);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onMarkDoneVCX = async(argVCXPoint)=>{
    var _url = 'api/essay/score/update/VCX'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, vcxPoint:argVCXPoint};
	  var _res = await AppHelper.apiPost(_url, _payload);
    console.log(_res);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onMarkDoneSP = async(argErrorCount)=>{
    var _url = 'api/essay/score/update/SP';
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:argErrorCount};
	  var _res = await AppHelper.apiPost(_url, _payload);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onPdfDownload = async()=>{
    setPdfDownloading(true);
    var _url = 'api/essay/share'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId};
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status || !_res.data || !_res.data.shareId) {
      setPdfDownloading(false);
		  alert('ERROR: Failed to add new share!');
		  return;
	  }
    var _shareUrl = AppHelper.shareUrl(_res.data.shareId);
    _shareUrl = _shareUrl.replace('/share', '/share/print');
    //var _shareUrl = 'http://localhost:3000/essay/share/print/'
    _shareUrl += '/' + oUser.token;
    _url = AppHelper.apiUrlGet('api/essay/pdf'); 
	  _payload = {
      url:_shareUrl, 
      grade: recEssay.grade};
    console.log('print url', _shareUrl);
    //_res = await AppHelper.apiPost(_url, _payload);
    try {
      var _resPdf = await AppHelper.apiPost('api/essay/pdf', _payload);
      console.log(_resPdf);
      if (!_resPdf || !_resPdf.status || !_resPdf.data) {
        setPdfDownloading(false);
        alert('ERROR: Failed to download the PDF!');
        return;
      }
      const linkSource = `data:application/pdf;base64,${_resPdf.data}`;
      const downloadLink = document.createElement('a');
      const link = document.createTextNode('Click here to download');

      // Set download attributes
      downloadLink.href = linkSource;
      downloadLink.download = `${recEssay.student}_${recEssay.grade}_${recEssay.topic}_${dayjs().format('YYYY-MM-DD')}.pdf`;

      // Append the link to the body (needed for some browsers)
      downloadLink.appendChild(link);
      document.body.appendChild(downloadLink);

      // Programmatically click the link to trigger the download
      downloadLink.click();

      // Remove the link after the download is triggered
      document.body.removeChild(downloadLink);

      // const response = await fetch(_url, {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(_payload),
      // });

      // if (!response.ok) {
      //     throw new Error('Network response was not ok');
      // }

      // const blob = await response.blob();
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = `${recEssay.student}_${recEssay.grade}_${recEssay.topic}_${dayjs().format('YYYY-MM-DD')}.pdf`;
      // document.body.appendChild(a);
      // a.click();
      // a.remove();
      // window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
    setPdfDownloading(false);
  }
	const onScan = async() => {
	  setPopupOpen(true);
  };
  
  const popShareClose = ()=>{
    setPopShareOpen(false);
  }
  
  const onTextSelectionChange = (arg)=>{
    console.log('box - selectionchange', arg);
  }
  const popCmtView = (arg, argScore)=>{
    //console.log(arg, argScore);
    if(comments[argScore.c] && comments[argScore.c].list.length>0){
      setPopCmtText(comments[argScore.c].list[0].comment);
    }
    setPopCmtType(argScore.c);
    setPopCmtOpen(true);
  }
  const popGMErrorsClose = ()=>{
    setPopGMErrorsOpen(false);
  }
  const popSpellingErrorsClose = ()=>{
    setPopSpellingErrorsOpen(false);
  }
  const popVCErrorsClose = ()=>{
    setPopVCErrorsOpen(false);
  }
  const popVCXClose = ()=>{
    setPopVCXOpen(false);
  }
	const scoreExpand = (argScoreI)=>{
    var _rec = {...recEssay};
    _rec.scores[argScoreI].expand = !_rec.scores[argScoreI].expand;
    setRecEssay(_rec);
  }
  const spellingErrorsView = (arg, param='')=>{
    if(arg.c == 'SP'){
      setScoreInfoSP(arg.scoreInfo);
      setPopSpellingErrorsOpen(!popSpellingErrorsOpen);
    }
    else if(arg.c == 'GM'){
      setScoreInfoGM(arg.scoreInfo);
      setPopGMErrorsOpen(!popGMErrorsOpen);
    }
    else if(arg.c == 'VC'){
      if(param == 'VCX'){
        setScoreInfoVCX(arg.scoreInfo);
        setPopVCXOpen(!popVCXOpen);
      }
      else{
        setScoreInfoVC(arg.scoreInfo);
        setPopVCErrorsOpen(!popVCErrorsOpen);
      }
    }
  }
  const wordCount = (_txt) =>{
    //setMsgText(_txt);
    if(!_txt) return 0;
    return _txt.trim().split(/\s+/).length;
  };
  const onReportDownload = async() => {
    var content = "1. ### This is an Essay titled `" + recEssay.topic + "` by a `Primary " + recEssay.grade.replace("P", "") + "` student `" + recEssay.student + "` (" + recEssay.email + ").\n\n";
    const textLines = recEssay.text.split('\n');
    textLines.forEach(line => {
      content += `\n>> ${line}`;
    });
	
    content += "\n\n2. ### This is a corrected copy of the essay with corrections to _spelling_ and _grammar_, and improvements to _phrasing_.\n\n";
    
    const text = recEssayC;
    
    const lines = text.split('\n');
    lines.forEach(line => {
      content += `\n>> ${line}`;
    });
    
    content += "\n\n3. ### Score across various categories:\n\n";
    var _scoreSP = recEssay.scores.find(s=>s.c == 'SP');
	var spMistakes = 0;
	if(_scoreSP.scoreInfo.levelItems0)
	{
		spMistakes = _scoreSP.scoreInfo.levelItems0.length;
	}
	if(_scoreSP.scoreInfo.levelItems1)
	{
		spMistakes += _scoreSP.scoreInfo.levelItems1.length;
	}
	if(_scoreSP.scoreInfo.levelItems2)
	{
		spMistakes += _scoreSP.scoreInfo.levelItems2.length;
	}
	
	content += '#### 3.1) Spelling: Score '+ _scoreSP.v + "/10" + ', ' + spMistakes + ' mistakes\n';
	
    if(_scoreSP && _scoreSP.scoreInfo){
      
      if(!_scoreSP.scoreInfo.levelItems0 || _scoreSP.scoreInfo.levelItems0.length<1){
        content += '\n* **Easy Mistakes (' + 0 + ' mistakes)**:';
        content += 'NA';
      }
      else{
		content += '\n* **Easy Mistakes (' + _scoreSP.scoreInfo.levelItems0.length + ' mistakes)**:';
        content += '\n| Wrong Spelling | Correct Spelling |';
        content += '\n| :------------:|:-------------:|';
        _scoreSP.scoreInfo.levelItems0.forEach(ssp=>{
          content += `\n| ${ssp.w} | ${ssp.r} |`;
        });
      }
     
      if(!_scoreSP.scoreInfo.levelItems1 || _scoreSP.scoreInfo.levelItems1.length<1){
		  content += '\n* **Medium Mistakes (' + 0 + ' mistakes)**:';
        content += 'NA';
      }
      else{
		  content += '\n* **Medium Mistakes (' + _scoreSP.scoreInfo.levelItems1.length + ' mistakes)**:';
        content += '\n| Wrong Spelling | Correct Spelling |';
        content += '\n| :------------:|:-------------:|';
        _scoreSP.scoreInfo.levelItems1.forEach(ssp=>{
          content += `\n| ${ssp.w} | ${ssp.r} |`;
        });
      }
      
      if(!_scoreSP.scoreInfo.levelItems2 || _scoreSP.scoreInfo.levelItems2.length<1){
		  content += '\n* **Difficult Mistakes (0 mistake)**:';
        content += 'NA';
      }
      else{
		  content += '\n* **Difficult Mistakes (' + _scoreSP.scoreInfo.levelItems2.length + ' mistakes)**:';
        content += '\n| Wrong Spelling | Correct Spelling |';
        content += '\n| :------------:|:-------------:|';
        _scoreSP.scoreInfo.levelItems2.forEach(ssp=>{
          content += `\n| ${ssp.w} | ${ssp.r} |`;
        });
      }
    }

    
    
    var _scoreGM = recEssay.scores.find(s=>s.c == 'GM');
	var gmMistakes = 0;
	if(_scoreGM && _scoreGM.scoreInfo) gmMistakes = _scoreGM.scoreInfo.errors.length;
	
    content += '\n#### 3.2) Grammar: Score '+ (_scoreGM ? _scoreGM.v : 0) + "/10" + ', ' + gmMistakes + ' mistakes\n';

	content += '* **Grammar Mistakes (' + gmMistakes + ' mistakes)**:';
    if(_scoreGM && _scoreGM.scoreInfo && _scoreGM.scoreInfo.errors && _scoreGM.scoreInfo.errors.length>0){
      content += '\n| Wrong Sentence | Correct Sentence| Type | Subtype |';
      content += '\n| :------------:|:-------------:|:-------------:|:-------------:|';
      _scoreGM.scoreInfo.errors.forEach(eGM=>{
        content += `\n| ${eGM.w} | ${eGM.r} | ${eGM.type} | ${eGM.subType} |`;
      });
    }
    else{
	  content += '* **Grammar Mistakes (0 mistake)**:';
      content += 'NA';
    }
    
    var _scoreVC = recEssay.scores.find(s=>s.c == 'VC');
	var vcMistakes = 0;
	
	if(_scoreVC && _scoreVC.scoreInfo) vcMistakes = _scoreVC.scoreInfo.errors.length;

    content += '\n#### 3.3) Vocabulary: Score '+ (_scoreVC ? _scoreVC.v : 0) + "/10" + ', ' + vcMistakes + ' mistakes\n';
	   
    if(_scoreVC && _scoreVC.scoreInfo){
      
      if(_scoreVC.scoreInfo.errors && _scoreVC.scoreInfo.errors.length>0){
		content += '* **Vocabulary Appropriateness: Score '+ (_scoreVC ? _scoreVC.v : 0) + '/10, (' + _scoreVC.scoreInfo.errors.length + ' mistakes)**:';
        content += '\n| Incorrect Usage Word | Incorrect Line | Suggested Correction | Explanation |';
        content += '\n| :------------:|:-------------:|:-------------:|:-------------:|';
        _scoreVC.scoreInfo.errors.forEach(eVC=>{
          content += `\n| ${eVC.w} | ${eVC.wl} | ${eVC.sg} | ${eVC.ex} |`;
        });
      }
      else{
		content += '* **Vocabulary Appropriateness: Score '+ (_scoreVC ? _scoreVC.v : 0) + '/10, 0 mistake**:';
        content += 'NA';
      }
      if(_scoreVC.scoreInfo.rareWords && _scoreVC.scoreInfo.rareWords.length>0){
		content += '\n* **Vocabulary Complexity: Score '+ (_scoreVC ? _scoreVC.v : 0) + '/10, ' + _scoreVC.scoreInfo.rareWords.length + ' words**:';
        content += '\n| Word | Type |';
        content += '\n|:----:|:----:|';
        _scoreVC.scoreInfo.rareWords.forEach(rw=>{
          content += `\n| ${rw.w} | ${rw.t == 'R'? 'Rare': rw.t=='U' ? 'Uncommon': '-'} |`;
        });
      }
    }

	var _scoreST = recEssay.scores.find(s=>s.c == 'ST');
    content += '\n#### 3.4) Structure & Flow: Score '+ (_scoreST ? _scoreST.v : 0) + "/10";
    content += '\n* **Score Categories**:';
    content += '\n| Score | Score Category |';
    content += '\n|:---:|:---:|';
    content += '\n| 1 | Needs Improvement |';
    content += '\n| 2 | Developing |';
    content += '\n| 3 | Competent |';
    content += '\n| 4 | Proficient |';
    
    if(_scoreST && _scoreST.sub && _scoreST.sub.length>0){
      _scoreST.sub.forEach(stSub=>{
        content += `${'\n\n`' + stSub.score + '/4`'} ${stSub.title}`;
        if(stSub.exampleImprovements && stSub.exampleImprovements.length>0){
          stSub.exampleImprovements.forEach(exi=>{
            content += `\n> * ${exi}`;
          });
        }
      });
    }
    content += '\n\n';

    var _scoreCT = recEssay.scores.find(s=>s.c == 'CT');
    if(_scoreCT){
      content += '\n#### 3.5) Relevance & Logic: Score '+ (_scoreCT ? _scoreCT.v : 0) + "/10";
      content += '\n* **Score Categories**:';
      content += '\n| Score | Score Category |';
      content += '\n|:---:|:---:|';
      content += '\n| 1 | Needs Improvement |';
      content += '\n| 2 | Emerging |';
      content += '\n| 3 | Developing |';
      content += '\n| 4 | Competent |';
      content += '\n| 5 | Skilled |';
      content += '\n| 6 | Proficient |';
      
      if(_scoreCT && _scoreCT.sub && _scoreCT.sub.length>0){
        _scoreCT.sub.forEach(stSub=>{
          content += `${'\n\n`' + stSub.score + '/6`'} ${stSub.title}`;
          if(stSub.exampleImprovements && stSub.exampleImprovements.length>0){
            stSub.exampleImprovements.forEach(exi=>{
              content += `\n> * ${exi}`;
            });
          }
        });
      }
      content += '\n\n';
    }

    var _scoreCR = recEssay.scores.find(s=>s.c == 'CR');
    if(_scoreCR){
      content += '\n#### 3.6) Development: Score '+ (_scoreCR ? _scoreCR.v : 0) + "/10";
      content += '\n* **Score Categories**:';
      content += '\n| Score | Score Category |';
      content += '\n|:---:|:---:|';
      content += '\n| 1 | Needs Improvement |';
      content += '\n| 2 | Emerging |';
      content += '\n| 3 | Developing |';
      content += '\n| 4 | Competent |';
      content += '\n| 5 | Skilled |';
      content += '\n| 6 | Proficient |';
      
      if(_scoreCR && _scoreCR.sub && _scoreCR.sub.length>0){
        _scoreCR.sub.forEach(stSub=>{
          content += `${'\n\n`' + stSub.score + '/6`'} ${stSub.title}`;
          if(stSub.exampleImprovements && stSub.exampleImprovements.length>0){
            stSub.exampleImprovements.forEach(exi=>{
              content += `\n> * ${exi}`;
            });
          }
        });
      }
      content += '\n\n';
    }

    content += '4. ### Overall Score: `' + recEssay.avgScoreP + '%`';
    const fileName = 'essay-report.txt';
    // console.log('------------- Report --------------');
    // console.log(content);

    // Create a Blob from the text
    const blob = new Blob([content], { type: 'text/plain' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    // Programmatically click the anchor to trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor and revoking the object URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
	
  }
	return(
		<>
		{ !isLoading && recEssay.topic &&
		<Box sx={{ flexGrow: 1 }}>
			<Grid container>
				<Grid item xs={6}>
					{ isShared &&
					<Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0.5rem'}}>
						{recEssay.student}
					</Typography>
					}
				</Grid>
				<Grid item xs={6}>
					<Box mb={1}  display={'flex'} justifyContent={'right'}>
						<Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0rem'}}>
							{/* {recGrade} */}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Box 
        sx={{
          borderBottomLeftRadius:'20px', borderBottomRightRadius:'20px',
          //maxHeight: printMode?'none':'30rem', 
          overflowY:'auto', 
          '&::-webkit-scrollbar': {
            width: '6px',      // Width of the scrollbar
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Track color
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',     // Thumb color
            borderRadius: '4px',   // Border radius
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',     // Thumb color on hover
          },
        }}>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Box display={'flex'} sx={{fontSize:'1.2rem', fontWeight:'bold', justifyContent:'space-between', overflowX:'hidden'}} ml={0}>
							<div style={{flexGrow:1}}>
              {recGrade && (
              <Box style={{textTransform:'Capitalize', marginLeft:'2px'}} sx={{fontSize:'0.82rem'}}>
              {recGrade}
              </Box>
              )}
								<div style={{display:'flex'}}>
									{ !isShared && configJson.allowPdf==1 && !printMode && !pdfDownloading &&
									<>
									<Button onClick={onPdfDownload} variant='text' style={{color:'rgba(19,130,117,1)',textTransform:'none', marginLeft:'-6px'}} endIcon={<PictureAsPdfIcon/>}>
										Download PDF
									</Button>
									</>
									}
									{ pdfDownloading &&
										<div style={{fontSize:'0.8rem', fontWeight:'normal'}}>Downloading..</div>
									}
								</div>
							</div>
							{((!printMode && recEssay.imgs && recEssay.imgs != undefined && recEssay.imgs.length > 0)) && (
								<>
								<Button onClick={onScan} variant='text' style={{color:'#000', textTransform:'none'}} endIcon={<WallpaperIcon/>}>Original Scan</Button>
								<ScanView isOpen={isPopupOpen} pages={recEssay.imgs} numPages={recEssay.imgs.length} onClose={handleClosePopup} />
								</>
							)}
							{ !printMode &&
							<Button onClick={copyStudentEssay} variant='text' style={{color:'#000',marginLeft:'0.5rem', textTransform:'none', paddingRight:'2px'}}
								endIcon={<ContentCopyIcon/>}>
								Copy Original
							</Button>
							}
						</Box>
            {recEssay.question != null &&
            <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px', marginTop:'0.25rem', fontSize:'18px'}}>
              <div style={{marginBottom:'8px'}}>Selected Question</div>
              <div> { <EssayViewQuestion question={recEssay.question} />} </div>
              
              
            </Card>
            } 
						<Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px', marginTop:'0.25rem'}}>
							<Grid item xs={12} mb={1}>
								<Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>{recEssay.topic}</Box>
							</Grid>
							<Grid item xs={12}>
               <Box id='essayViewBox' style={{ cursor: 'context-menu' }}
									onSelect={onTextSelectionChange}
									sx={{
										fontSize:'1.3rem', lineHeight:'35px', fontFamily: "'Playpen Sans', cursive",
										background:'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize:'100% 35px',
										border:'0 solid #efefef', borderRadius:'6px', padding:'5px', marginLeft:'5px', 
									}}
								>
                  {getDifferences()}
								</Box>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</Box>
			<Grid container>
        <Grid item xs={12} sm={6}>
          <Box id='boxWordCount' sx={{mb:1}}>
            {wordsCount} words
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{mb:1}}>
            {/* {wordsCount2} words */}
          </Box>
        </Grid>
      </Grid>
			<Grid container pt={.5}>
        <Grid item xs={12} md={recVersion==1?6:12}>
          <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px'}}>
					{ recEssay && recVersion>=3 &&
						<>
							<div id="boxPageBreak"></div>
              <Box display='flex' width='100%' style={{marginBottom:'1rem'}}>
                <Box flexGrow={1}>
                  <Box id='boxScoreTitle' sx={{fontWeight:'bold', fontSize:'2rem'}}>SCORE</Box>
                  <Box> 
                  { recEssay && recEssay.scores && recEssay.scores.map((sc, iSc)=>(
                    <Grid container mb={0.6} key={'score_' + iSc}>
											<Grid item xs={12}>
												<Box display='flex' width='100%'>
													<Box flexGrow={1} paddingRight={'.2rem'}>
														<Box flexGrow={1} 
															sx={{
																border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex',
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis'
															}}>
															<Box className='app-bg-g' sx={{ borderRadius:'50px', 
																//backgroundColor:'rgb(99, 136, 137)', 
																width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' 
															}}>
																{sc.v}/{recEssay.version>=3?'10':'4'} {sc.n}
															</Box>
														</Box>
													</Box>
													<Box>
														{ isResubmitting && 
															<span>Resubmitting..</span>
														}
														
														{/* { sc.eg &&
															<div>{sc.eg.length}</div>
														} */}
														{ sc.eg && (sc.eg.length > 0 || sc.c=='VC') && (
															<>
															<Chip onClick={()=>scoreExpand(iSc)}
																icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length>0?sc.eg.length:''} 
																className='app-bg-g'
																sx={{
																	//backgroundColor:'rgb(99, 136, 137)',
																	color: '#fff',
																	fontWeight: 'bold',
																	[`& .${chipClasses.icon}`]: {
																		color: '#fff'
																	},
																	width:'65px'
																}} 
															/>
															</>
														)}
														{ iamTEA && !isSample && !isShared && isMyEssay &&
															(sc.c!='GM' || isOK_GM) && (sc.c!='SP' || isOK_SP) && 
															(sc.c!='VC' || isOK_VC) && (sc.c!='ST' || isOK_ST) && 
															<Chip onClick={()=>popCmtView(iSc, sc)}
																icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
																label={'Comment'}
																className='app-bg-g'
																sx={{
																	//backgroundColor:'rgb(99, 136, 137)',
																	color: '#fff',
																	fontWeight: 'bold', marginLeft:'2px',
																	[`& .${chipClasses.icon}`]: {
																		color: '#fff'
																	}
																}} 
															/>
														}
													</Box>
												</Box>
											</Grid>

											{ sc.expand && sc.sub && 
                        <ScoreSubExpand recordSc={sc} scoreCode={sc.c} records={sc.sub} essay={recEssay} allowMark={(iamTEA || iamTUI) && !isShared && isMyEssay} />
                      }
                      { sc.expand && sc.scoreInfo &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>
                            { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>0 &&(
                            <>
                              <Box>
                                <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {sc.scoreInfo.blocks[0].title}
                                { !sc.scoreInfo.blocks[0].hide && (
                                  // <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                  //   <OpenInNewIcon />
                                  // </Fab>
                                  <>
                                  { sc.c=='VC' &&
                                    <span onClick={()=> spellingErrorsView(sc)} className='showMoreLess'>
                                      { popVCErrorsOpen ? " Show Less" : " Show More"}
                                    </span>
                                  }
                                  </>
                                )}
                                </Typography>
                              </Box>
                              { sc.c=='VC' && popVCErrorsOpen &&
                                 <PopVCAErrors
                                 open={popVCErrorsOpen} onClose={popVCErrorsClose}
                                 scoreInfo={scoreInfoVC}
                                 essay={recEssay}
                                 allowMark={(iamTEA || iamTUI) && !isShared && isMyEssay}
                                 onMarkDone={onMarkDoneVCA}
                               />
                              }
                              { sc.scoreInfo.blocks[0].subTitles && sc.scoreInfo.blocks[0].subTitles.map((rSubT, iSubT)=>(
                                <Box>
                                  <Grid item xs={12} mt={0}>
                                    <Box display={'flex'} alignItems={'center'}>
                                      <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                        {rSubT}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Box>
                              ))}
                            </>  
                            )}
                            { (!sc.scoreInfo.blocks || sc.scoreInfo.blocks.length<1) &&(
                            <>
                            <Box>
                              <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {sc.scoreInfo.title}
                                { sc.c=='GM' &&
                                  <span onClick={()=> spellingErrorsView(sc)} className='showMoreLess'>
                                    { popGMErrorsOpen ? " Show Less" : " Show More"}
                                  </span>
                                }
                                { sc.c=='SP' &&
                                  <span onClick={()=> spellingErrorsView(sc)} className='showMoreLess'>
                                    { popSpellingErrorsOpen ? " Show Less" : " Show More"}
                                  </span>
                                }
                              </Typography>
                              {/* <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)', minWidth:'40px'}}>
                                <OpenInNewIcon />
                              </Fab> */}
                            </Box>
                            <Box>
                            { sc.c=='GM' && popGMErrorsOpen &&
                              <PopGMErrors
                                  open={popGMErrorsOpen} onClose={popGMErrorsClose}
                                  scoreInfo={scoreInfoGM}
                                  essay={recEssay}
                                  allowMark={(iamTEA || iamTUI) && !isShared && isMyEssay}
                                  onMarkDone={onMarkDoneGM}
                                />
                            }
                            { sc.c=='SP' && popSpellingErrorsOpen &&
                              <PopSPErrors
                                open={popSpellingErrorsOpen} onClose={popSpellingErrorsClose}
                                scoreInfo={scoreInfoSP}
                                essay={recEssay}
                                allowMark={(iamTEA || iamTUI)  && !isShared && isMyEssay}
                                onMarkDone={onMarkDoneSP}
                              />
                            }
                            </Box>
                            </>
                            )}
                          {/* <Chip onClick={()=> spellingErrorsView(sc)}
                            icon={<OpenInNewIcon />}
                            sx={{
                              backgroundColor:'rgb(99, 136, 137)',
                              color: '#F9EFDB',
                              fontWeight: 'bold', marginLeft: '5px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                            /> */}
                          </Box>
                        </Grid>
                        { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>1 &&(
                        <Grid item xs={12} mt={0.5}>
                          <Box>
                            <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                              {sc.scoreInfo.blocks[1].title}
                              { sc.c=='VC' &&
                                <span onClick={()=> spellingErrorsView(sc, 'VCX')} className='showMoreLess'>
                                  { popVCXOpen ? " Show Less" : " Show More"}
                                </span>
                              }
                            </Typography>
                            {/* <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                              <OpenInNewIcon />
                            </Fab> */}
                          </Box>
                          { sc.c=='VC' && popVCXOpen &&
                            <PopVCXErrors
                              open={popVCXOpen} onClose={popVCXClose}
                              scoreInfo={scoreInfoVCX}
                              essay={recEssay}
                              allowMark={(iamTEA || iamTUI)  && !isShared && isMyEssay}
                              onMarkDone={onMarkDoneVCX}
                            />
                          }
                          { sc.scoreInfo.blocks[1].subTitles && sc.scoreInfo.blocks[1].subTitles.map((rSubT, iSubT)=>(
                            <Grid item xs={12} mt={0}>
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                  {rSubT}
                                </Typography>
                                {/* { iSubT==0 && sc.scoreInfo.rareWords && sc.scoreInfo.rareWords.length>0 && (
                                  <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                    <OpenInNewIcon />
                                  </Fab>
                                )} */}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        )}
                        { sc.scoreInfo.subTitles && sc.scoreInfo.subTitles.map((rSubT, iSubT)=>(
                          <Grid item xs={12} mt={0}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {rSubT}
                              </Typography>
                              {/* { iSubT==0 && sc.scoreInfo.rareWords && sc.scoreInfo.rareWords.length>0 && (
                                <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                  <OpenInNewIcon />
                                </Fab>
                              )} */}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      }
										</Grid>
									))}
									</Box>
								</Box>
                <Box>
                { printMode && qrValue &&
                  <div  style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', paddingLeft:'1rem'}}>
                    <div style={{textAlign:'center', marginTop:0, borderRadius:'0'}}>
                      <div style={{color:"rgba(19,130,117,1)", fontWeight:'900', textAlign:'center', marginBottom:'.5rem', lineHeight:1.1}}>Scan the QR Code <br/>for detailed feedback!</div>
                      <QRCodeSVG 
					  id='boxQR'
                      value={qrValue} 
                      //value={'ABCD'}
                      size={150} 
                      level={"Q"} includeMargin={false}
                      fgColor={"rgba(19,130,117,1)"}
                      style={{borderRadius:'0'}}
                      />
                    </div>
                  </div>
                }
                </Box>
							</Box>
              <Grid item xs={12}>
                <Box display='flex' width='100%'>
                  <Box flexGrow={1} paddingRight={'.2rem'}>
                    <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
                      <Box ref={boxOverall} id="boxOverall" sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
                        {/* Overall {Math.floor(recEssay.avgScoreP)}% */}
                        Overall {Math.round(recEssay.avgScoreP)}%
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    { !printMode &&
                    <>
                    <div style={{width:'65px'}}></div>
                    { iamTEA && !isSample && !isShared && isMyEssay &&
                      <Chip onClick={()=>popCmtView(-1, {c:'OA'})}
                        icon={<img src='/img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                        label={'Comment'}
                        className='app-bg-g'
                        sx={{
                          //backgroundColor:'rgb(99, 136, 137)',
                          color: '#fff',
                          fontWeight: 'bold', marginLeft:'2px',
                          [`& .${chipClasses.icon}`]: {
                            color: '#fff'
                          }
                        }}
                      />
                    }
                    </>
                    }
                  </Box>
                </Box>
              </Grid>
						</>
					}
					</Card>
				</Grid>
        <Grid item xs={12} md={6} pl={0}>
          { recEssay && recEssay.suggestions && recEssay.suggestions.length>0 &&
            <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px'}}>
              <Grid item xs={12}>
                <Box sx={{fontWeight:'bold', fontSize:'2rem'}}>SUGGESTIONS</Box>
              </Grid>
              <Grid item xs={12}>
                { recEssay && recEssay.suggestions && recEssay.suggestions.map((sg,iSg)=>(
                  // <Box key={'sug_' + iSg}>{sg}</Box>
                  <ListItem key={'sug_' + iSg} sx={{alignItems:'flex-start'}}>
                    <ListItemIcon sx={{minWidth:'40px'}}>
                      <AssignmentIcon sx={{color:'rgb(157, 188, 152)', marginTop:'8px'}} />
                    </ListItemIcon>
                    <ListItemText
                      primary={sg}
                      // secondary={secondary ? 'Secondary text' : null}
                    />
                  </ListItem>
                ))}
              </Grid>
            </Card>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} mt={1}>
              { !isSample && !isShared && (oUser && oUser.token != 'NA') && (
              <>
                { isMyEssay &&
                <>
                <Button onClick={onDelete} color="error" variant="outlined" style={{marginRight:'2px'}}>DELETE</Button>
                { <Button onClick={onShare} color="success" variant="outlined" style={{marginRight:'2px'}}>SHARE</Button>
                /*<Button onClick={onScoreView} color="success" variant="outlined" style={{marginRight:'2px'}}>SCORE SIMULATION</Button> */}
                </>
                }
                {isSa && (
                  <>  
                  {/* <Button onClick={onShowLog} color="success" variant="outlined" style={{marginRight:'2px'}}>VIEW LOG</Button> */}
                  <Button onClick={onReportDownload} color="success" variant="outlined" >
                    REPORT
                  </Button>
                  </>
                )}
              </>
              )}
            </Grid>
            <Grid item xs={6} mt={1} style={{textAlign:'right'}}>
              { isSa && 
              <Chip label='EXERCISE'
                onClick={()=> browseExercise()}
                icon={<OpenInNewIcon />}
                className='app-bg-g'
                sx={{
                  //backgroundColor:'rgb(99, 136, 137)',
                  color: '#fff',
                  fontWeight: 'bold', marginLeft: '5px', marginRight:'5px',
                  [`& .${chipClasses.icon}`]: {
                    color: '#fff'
                  }
                }}
              />
              }
            </Grid>
          </Grid>
        </Grid>
			</Grid>
		</Box>
		}
		{ isLoading &&
      <CircularProgress />
    }
	
	 <PopShare
        open={popShareOpen} onClose={popShareClose}
        scoreInfo={scoreInfoVCX}
		    essay={recEssay}
        allowMark={iamTEA}
        onMarkDone={onMarkDoneVCX}
      />
	
		</>
	);
}